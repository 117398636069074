import packageJson from '../package.json';

export type ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: string | undefined;
        MENU_SERVICE: string | undefined;
        MAP_CREDENTIALS_SERVICE: string | undefined;
        HERE_AUTO_COMPLETE_SERVICE: string | undefined;
        HERE_AUTO_SUGGEST_SERVICE: string | undefined;
        HERE_REV_GEOCODING_SERVICE: string | undefined;
        ORDER_EXCHANGE_SERVICE: string | undefined;
        TOURS_SERVICE: string | undefined;
        TOURS_FRONTEND: string | undefined;
        PARTNER_NETWORK_SERVICE: string | undefined;
        USER_MANAGEMENT_SERVICE: string | undefined;
        ASSET_ADMINISTRATION_SERVICE: string | undefined;
    };
    homeRoute: string | undefined;
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        mockAuthorization: boolean;
        mockLocale: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
    };
    serviceVersion: string;
    serviceEnvironment: string;
    enableMockServer: boolean;
    logoutUri: string | undefined;
    sentryToken: string | undefined;
    sentryModuleName: string;
};

const getBoolValue = (value: string | undefined): boolean => {
    return value === 'true';
};

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        MENU_SERVICE: import.meta.env.VITE_MENU_SERVICE,
        MAP_CREDENTIALS_SERVICE: import.meta.env.VITE_MAP_CREDENTIALS_SERVICE,
        HERE_AUTO_COMPLETE_SERVICE: import.meta.env.VITE_HERE_AUTO_COMPLETE_SERVICE,
        HERE_AUTO_SUGGEST_SERVICE: import.meta.env.VITE_HERE_AUTO_SUGGEST_SERVICE,
        HERE_REV_GEOCODING_SERVICE: import.meta.env.VITE_HERE_REV_GEOCODING_SERVICE,
        ORDER_EXCHANGE_SERVICE: import.meta.env.VITE_ORDER_EXCHANGE_SERVICE,
        TOURS_SERVICE: import.meta.env.VITE_TOURS_SERVICE,
        TOURS_FRONTEND: import.meta.env.VITE_TOURS_FRONTEND,
        PARTNER_NETWORK_SERVICE: import.meta.env.VITE_PARTNER_NETWORK_SERVICE,
        USER_MANAGEMENT_SERVICE: import.meta.env.VITE_USER_MANAGEMENT_SERVICE,
        ASSET_ADMINISTRATION_SERVICE: import.meta.env.VITE_ASSET_ADMINISTRATION_SERVICE,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: '721e59a3-33af-4e86-9b3c-fec696b16d71',
        oauthScope: [
            'openid',
            'profile',
            'email',
            'phone',
            'menu.read',
            'map.read',
            'asset-administration.read',
            'tour.read',
            'tour.write',
        ],
        mockAuthorization: import.meta.env.DEV,
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        preventRedirect: getBoolValue(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    serviceVersion: packageJson.version,
    serviceEnvironment: import.meta.env.MODE,
    enableMockServer: import.meta.env.DEV,
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    sentryToken: import.meta.env.VITE_SENTRY_DSN,
    sentryModuleName: 'transport-order-exchange-web',
};
