import { configureStore } from '@reduxjs/toolkit';

import configReducer from './configSlice';
import loginReducer from '../login/loginSlice';
import langReducer from '../lang/langSlice';
import tokenReducer from '../tokenHandling/tokenSlice';
import appReducer from '../../layout/appSlice';
import { mapCredentialsApi } from '../../services/mapCredentials/mapCredentials.api';
import { hereAutoSuggestApi } from '../../services/here/hereAutoSuggestApi';
import { reverseGeocodeApi } from '../../services/here/reverseGeocodeApi';
import { transportOrdersApi } from '../../services/orderExchange/orderExchange.api';
import { partnerNetworkApi } from '../../services/partnerNetwork/partnerNetwork.api';
import { accountsApi } from '../../services/accounts/accounts.api';
import { assetAdministrationApi } from '../../services/assetAdministration/assetAdministration.api';
import { setupListeners } from '@reduxjs/toolkit/query';

export const store = configureStore({
    reducer: {
        config: configReducer,
        lang: langReducer,
        app: appReducer,
        login: loginReducer,
        tokenHandling: tokenReducer,

        // Add the generated reducer as a specific top-level slice
        [mapCredentialsApi.reducerPath]: mapCredentialsApi.reducer,
        [hereAutoSuggestApi.reducerPath]: hereAutoSuggestApi.reducer,
        [reverseGeocodeApi.reducerPath]: reverseGeocodeApi.reducer,
        [transportOrdersApi.reducerPath]: transportOrdersApi.reducer,
        [partnerNetworkApi.reducerPath]: partnerNetworkApi.reducer,
        [accountsApi.reducerPath]: accountsApi.reducer,
        [assetAdministrationApi.reducerPath]: assetAdministrationApi.reducer,
    },

    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat([
            mapCredentialsApi.middleware,
            hereAutoSuggestApi.middleware,
            reverseGeocodeApi.middleware,
            transportOrdersApi.middleware,
            partnerNetworkApi.middleware,
            accountsApi.middleware,
            assetAdministrationApi.middleware,
        ]),
});

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
